@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.reveala {
  border: 3px solid red;
}

#hero-div {
  background: url(../images/background.svg);
  background-size: 100vw;
  background-repeat: no-repeat;
  background-position: center;
}

/* editor's styles */
.editor-content-wrapper {
  border: 1px solid red;
  color: green;
}

/* active selected route on home page */
.active {
  color: "#2ABEC3";
}

.arr_btn:hover {
  padding-left: 5px;
}

.custombutton {
  @apply w-fit h-fit px-0 py-0 rounded-full;
}

.custombutton span {
  @apply w-fit h-fit rounded-full px-0 py-0;
}/*# sourceMappingURL=index.css.map */